<template>
  <div>
    <b-row class="no-gutters">
      <b-col md="6" class="text-left">
        <!-- <b-row>
          <p class="font-weight-bold my-2 mx-3 search-text">Search:</p>
          <b-col md="6" class="mt-1 justify-content-start">
            <b-form-input
              @keyup="handleSearchProductList"
              placeholder="Name"
              v-model="filter.search"
            >
            </b-form-input>
          </b-col>
          <b-col md="2" class="px-0 justify-content-start">
            <b-iconstack font-scale="3" type="submit" @click="handleSearchProductList">
              <b-icon stacked icon="circle-fill" class="main-color"></b-icon>
              <b-icon stacked icon="search" scale="0.5" variant="white"></b-icon>
            </b-iconstack>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-form-input
                placeholder="Search Name"
                v-model="filter.search"
                @keyup.enter="handleSearchProductList"
          
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text>
                  <b-iconstack
                    font-scale="2"
                    type="submit"
                    @click.prevent="handleSearchProductList"
                  >
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                    ></b-icon>
                  </b-iconstack>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6" class="text-right">
        <b-form-checkbox size="lg" v-model="showOnly" @change="setshowOnly()"
          >Show Only Selected Category</b-form-checkbox
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-3">
        <b-table
          responsive
          striped
          :fields="fields"
          :items="productList"
          :busy="isBusy"
          :per-page="filter.perPage"
          :current-page="filter.pageNo"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(id)="data">
            <b-form-checkbox
              size="lg"
              class="ml-3"
              :value="data.item.id"
              v-model="productSelectedList"
              @change="checkActive(data.item)"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(image_url)="data">
            <div
              class="d-flex justify-content-center"
              v-if="data.item.image_url"
            >
              <b-img
                class="box-image-component1-1"
                :src="data.item.image_url"
                fluid
              ></b-img>
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <span class="mb-0 nobreak two-lines">{{ data.item.name }}</span>
          </template>
          <template v-slot:cell(sort_order)="data">
            <b-form-input
              v-if="refreshInput"
              v-model="data.item.sort_order"
              type="number"
              :disabled="data.item.active"
              @change="addProduct(data.item)"
              placeholder="Plaease insert sort order"
            ></b-form-input>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="4"
        class="form-inline justify-content-center justify-content-sm-start mb-2 mb-sm-0"
      >
        <p class="mb-0 p-gray">
          Showing {{ showing }} to {{ showingTo }} of {{ rows }} entries
        </p>
      </b-col>
      <b-col
        cols="12"
        md="8"
        class="form-inline justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="filter.page"
          :total-rows="rows"
          :per-page="filter.take"
          class="m-md-0"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="pagination"
        ></b-pagination>
        <div class="ml-1 mt-3 mt-sm-0">
          <b-form-select
            v-model="filter.take"
            @change="hanndleChangePerpage"
            :options="pageOptions"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    productList: {
      required: true,
      type: Object | Array,
    },
    rows: {
      required: false,
      type: Number,
    },
    idList: {
      required: false,
      type: Array,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
    showingTo: {
      required: false,
      type: Number,
    },
    showing: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      productSelectedList: this.idList,
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "5%" },
        },
        {
          key: "image_url",
          label: "Image",
          thStyle: { width: "10%" },
          tdClass: "p-1",
        },
        {
          key: "name",
          label: "Product Name",
          tdClass: "text-center",
        },
        {
          key: "sort_order",
          label: "Sort Order",
          tdClass: "text-center",
          thStyle: { width: "15%" },
        },
      ],
      showOnly: false,
      filter: {
        take: 5,
        page: 1,
        search: "",
        productIdList: [],
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      refreshInput: true,
    };
  },
  methods: {
    pagination() {
      Vue.nextTick(() => {
        this.$emit("pagination", this.filter.page);
      });
    },
    setshowOnly() {
      this.filter.page = 1;
      Vue.nextTick(() => {
        this.$emit("setShowonly", this.showOnly);
      });
    },
    handleSearchProductList(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.$emit("handleSearchProductList", this.filter.search);
        }
      } else if (e.type === "click") {
        this.$emit("handleSearchProductList", this.filter.search);
      }
    },
    hanndleChangePerpage() {
      Vue.nextTick(() => {
        this.$emit("hanndleChangePerpage", this.filter.take);
      });
    },
    onCheckDisable(index) {
      return this.PurchaseChanelList[index].active;
    },
    checkItemActive(id) {
      let target = this.PurchaseChanelList.find((el) => el.id == id);
      return target.active;
    },
    checkActive(item) {
      item.active = !item.active;
      this.refreshInput = false;
      this.$nextTick(() => {
        this.refreshInput = true;
      });
      Vue.nextTick(() => {
        this.$emit("setProductID", item);
      });
      if (item.active == true) {
        item.sort_order = 0;
        Vue.nextTick(() => {
          this.$emit("setSpliceSelectProduct", item.id);
        });
      } else {
        Vue.nextTick(() => {
          this.$emit("setSpliceDeleteProduct", item.id);
        });
      }
    },
    addProduct(list) {
      Vue.nextTick(() => {
        this.$emit("setProductID", list);
      });
    },
  },
};
</script>

<style scoped>
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
</style>
