<template>
  <div>
    <HeaderPanel
      title="Category"
      :hasSearch="false"
      :hasFilter="false"
      :hasDropdown="false"
    />
    <div v-if="isLoadingData">
      <img src="@/assets/icons/loading.svg" class="loading" alt="loading" />
    </div>
    <div v-else class="mt-3">
      <div class="no-gutters bg-white p-2 p-lg-3">
        <div>
          <b-row>
            <b-col md="4" class="pr-0">
              <CategoryList
                :items="items"
                :activeItem="activeItem"
                :parentList="parentList"
                @handleSetMainCategoryData="createMainCategory"
                @handleSetSubCategoryData="createSubCategory"
                @handleGetCategoryData="getCategoryData"
              />
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col sm="12" md="6">
                  <InputText
                    textFloat="Name"
                    placeholder="Name"
                    type="text"
                    name="name"
                    isRequired
                    v-model="form.category.name"
                    :v="$v.form.category.name"
                  />
                </b-col>
                <b-col sm="12" md="6">
                  <InputText
                    textFloat="Sort Order"
                    placeholder="Sort Order"
                    type="number"
                    name="sortorder"
                    v-model="form.category.sort_order"
                  />
                </b-col>
                <b-col md="12" v-if="isSubCategory" class="mb-3">
                  <b-row>
                    <b-col md="6">
                      <InputSelect
                        title="Parent Category"
                        name="Parent Category"
                        v-model="form.category.parent_id"
                        isRequired
                        v-bind:options="categorys"
                        valueField="id"
                        textField="name"
                        :noPleaseSelect="noPleaseSelect"
                        @onDataChange="handleChangeParentCategory"
                        :disabled="isDisable"
                        :v="checkrequiredtype"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="12">
                  <UploadFile
                    textFloat="Cover Image"
                    placeholder="Please Choose File"
                    format="all"
                    :fileName="form.category.image_url"
                    v-model="form.category.image_url"
                    name="thumbnail"
                    text="*Please upload only file .png, .jpg with ratio 1:1"
                    isRequired
                    :accept="acceptType"
                    v-on:onFileChange="onImageChange"
                    v-on:delete="deleteImage"
                    :v="$v.form.category.image_url"
                  />
                  <PreviewBox
                    v-on:delete="deleteImage"
                    :disabled="isDisable"
                    :showPreviewType="0"
                    :showPreview="showPreview"
                    :isLoadingImage="isLoadingImage"
                    :ratioType="1"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" v-if="isSubCategory">
                  <div class="mt-3 panelborder">
                    <div class="title-tabs">Product List</div>
                    <div class="p-3 no-gutters bg-white">
                      <ProductList
                        :productList="productList"
                        :idList="IdList"
                        :rows="rows"
                        :isBusy="isBusy"
                        :disabled="isDisable"
                        v-on:setProductID="setProductID"
                        v-on:setSpliceSelectProduct="setSpliceSelectProduct"
                        v-on:setSpliceDeleteProduct="setSpliceDeleteProduct"
                        :showingTo="showingTo"
                        :showing="showing"
                        v-on:pagination="pagination"
                        v-on:setShowonly="setShowonly"
                        @handleSearchProductList="handleSearchProductList"
                        @hanndleChangePerpage="hanndleChangePerpage"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="no-gutters bg-white mt-3 py-2 px-3">
        <b-form-checkbox
          switch
          v-model="form.category.status"
          class="radio-active"
          size="lg"
          :value="1"
          :unchecked-value="0"
        >
          <span class="ml-2 main-label">{{
            form.category.status ? "Active" : "Inactive"
          }}</span>
        </b-form-checkbox>
      </div>
      <FooterAction @submit="checkForm(0)" />
    </div>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import { required } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import CategoryList from "./components/CategoryList";
import FooterAction from "@/components/FooterAction";
import ProductList from "@/components/product/ProductListInCategory";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  name: "Index",
  components: {
    FooterAction,
    InputText,
    InputSelect,
    CategoryList,
    ProductList,
    UploadFile,
    PreviewBox,
  },
  data() {
    return {
      acceptType: "image/jpeg, image/png",
      isBusy: false,
      isProductInCategory: true,
      activeItem: 0,
      isEdit: false,
      isLoadingData: false,
      isDisable: false,
      isLoadingImage: false,
      isSubCategory: false,
      coverImgType: 1,
      showPreview: "",
      rows: 0,
      productList: [],
      IdList: [],
      parentList: [],
      categorys: [],
      showing: 1,
      showingTo: 5,
      items: {},
      noPleaseSelect: true,
      filterGetcategory: {
        search: "",
        includeSubcategory: false,
      },
      newEditSelectProduct: [],
      filterGetProduct: {
        search: "",
        page: 1,
        take: 5,
        category_id: 0,
        show_only_selected: false,
        select_product: [],
        delete_product: [],
      },
      selectProduct: [],
      deleteProduct: [],
      form: {
        category: {
          name: "",
          image_url: "",
          sort_order: 0,
          status: false,
          parent_id: 0,
        },
      },
      checkrequiredtype: {
        required: null,
      },
      image: {
        width: "",
        height: "",
      },
    };
  },
  validations: {
    form: {
      category: {
        name: { required },
        image_url: { required },
      },
    },
  },
  created: async function () {
    await this.getCategoryList();
    await this.getParentCategoryList();
  },
  methods: {
    getCategoryList: async function () {
      this.isLoadingData = true;
      this.filterGetcategory.includeSubcategory = true;
      await this.$store.dispatch(
        "ActionGetAllCategory",
        this.filterGetcategory
      );
      var data = await this.$store.state.moduleConnectAPI.stateGetAllCategory;
      if (data.result == 1) {
        this.items = data.detail;
        this.isLoadingData = false;
      }
    },
    getParentCategoryList: async function () {
      this.isLoadingData = true;
      this.filterGetcategory.includeSubcategory = false;
      await this.$store.dispatch(
        "ActionGetAllCategory",
        this.filterGetcategory
      );
      var data = await this.$store.state.moduleConnectAPI.stateGetAllCategory;
      if (data.result == 1) {
        this.categorys = data.detail;
        this.isLoadingData = false;
      }
    },
    handleChangeParentCategory: async function (value) {
      this.form.category.parent_id = value;
    },
    createMainCategory: async function () {
      this.isSubCategory = false;
      this.form.category.name = "";
      this.form.category.image_url = "";
      this.form.category.status = false;
      this.form.category.sort_order = 0;
      this.form.category.parent_id = 0;
      this.filterGetProduct.select_product = [];
      this.filterGetProduct.delete_product = [];
      this.filterGetProduct.show_only_selected = false;
      this.showPreview = "";
      this.checkrequiredtype.required = null;
      this.getCategoryData(0, "maincategory");
    },
    createSubCategory: async function (id) {
      this.isSubCategory = true;
      this.form.category.name = "";
      this.form.category.image_url = "";
      this.form.category.status = false;
      this.form.category.sort_order = 0;
      this.form.category.parent_id = 0;
      this.filterGetProduct.category_id = 0;
      this.filterGetProduct.select_product = [];
      this.filterGetProduct.delete_product = [];
      this.filterGetProduct.show_only_selected = false;
      this.checkrequiredtype.required = null;
      this.showPreview = "";
      await this.getCategoryData(0, "subcategory");
    },
    getCategoryData: async function (id, name) {
      this.isLoadingData = true;
      // if (this.activeItem !== 0) {
      //   this.filterGetProduct.select_product = []
      //   this.filterGetProduct.delete_product = []
      // }
      if (name === "maincategory") {
        this.isSubCategory = false;
      } else {
        this.isSubCategory = true;
      }
      await this.$store.dispatch("ActionGetCategoryByID", id);
      var data = await this.$store.state.moduleConnectAPI.stateGetCategoryByID;
      if (data.result == 1) {
        this.isLoadingData = false;
        if (data.detail.length !== 0) {
          this.form.category = data.detail[0];
          this.showPreview = data.detail[0].image_url;
          this.activeItem = data.detail[0].id;
          if (data.detail[0].status === 0) {
            this.form.category.status = false;
          } else {
            this.form.category.status = true;
          }
        } else {
          this.activeItem = 0;
        }
        this.$v.form.$reset();
      }
      await this.getProductList();
    },
    setProductID: async function (val) {
      const even = (element) => element === val.id;
      var ischeck = this.IdList.some(even);
      const condition = (element) => element.id === val.id;
      var idIndex = this.filterGetProduct.select_product.findIndex(condition);
      if (this.activeItem === 0) {
        if (ischeck === false) {
          this.filterGetProduct.select_product.push({
            id: val.id,
            sort_order: parseInt(val.sort_order),
          });
          this.IdList.push(val.id);
        } else {
          var data = {
            id: val.id,
            sort_order: parseInt(val.sort_order),
          };
          this.filterGetProduct.select_product[idIndex] = data;
          this.IdList[idIndex] = val.id;
        }
      } else {
        if (this.activeItem !== 0) {
          const even = (element) => element.id === val.id;
          var ischeckEdit = this.newEditSelectProduct.some(even);
          const condition = (element) => element.id === val.id;
          var idIndexEdit = this.newEditSelectProduct.findIndex(condition);
          if (ischeck === false && ischeckEdit === false) {
            this.newEditSelectProduct.push({
              id: val.id,
              sort_order: parseInt(val.sort_order),
            });
          } else if (ischeck === true && ischeckEdit === true) {
            var dataEdit = {
              id: val.id,
              sort_order: parseInt(val.sort_order),
            };
            this.newEditSelectProduct[idIndexEdit] = dataEdit;
          } else if (ischeck === true && ischeckEdit === false) {
            this.newEditSelectProduct.push({
              id: val.id,
              sort_order: parseInt(val.sort_order),
            });
          } else if (ischeck === false && ischeckEdit === true) {
            var dataEdit2 = {
              id: val.id,
              sort_order: parseInt(val.sort_order),
            };
            this.newEditSelectProduct[idIndexEdit] = dataEdit2;
          }
          // newEditSelectProduct
          // const condition = (element) => element.id === val.id;
          // var idIndex = this.filterGetProduct.select_product.findIndex(condition)
        }
      }
    },
    setSpliceSelectProduct(id) {
      const even = (element) => element.id === id;
      var ischeck = this.filterGetProduct.select_product.some(even);
      const condition = (element) => element.id === id;
      var idIndex = this.filterGetProduct.select_product.findIndex(condition);
      const evenEdit = (element) => element.id === id;
      var ischeckEdit = this.newEditSelectProduct.some(evenEdit);
      const conditionEdit = (element) => element.id === id;
      var idIndexEdit =
        this.filterGetProduct.select_product.findIndex(conditionEdit);
      if (ischeck) {
        this.filterGetProduct.delete_product.push(
          this.filterGetProduct.select_product[idIndex].id
        );
        this.filterGetProduct.select_product.splice(idIndex, 1);
        this.selectProduct.splice(idIndex, 1);
      }
      // if (ischeckEdit) {
      //   this.filterGetProduct.delete_product.push(this.filterGetProduct.select_product[idIndexEdit].id)
      //   this.newEditSelectProduct.splice(idIndexEdit, 1)
      // }
    },
    setSpliceDeleteProduct(id) {
      const even = (element) => element === id;
      var ischeck = this.filterGetProduct.delete_product.some(even);
      const condition = (element) => element.id === id;
      var idIndex = this.filterGetProduct.delete_product.findIndex(condition);
      if (ischeck) {
        this.filterGetProduct.delete_product.splice(idIndex, 1);
      }
    },
    handleSearchProductList(searchName) {
      this.filterGetProduct.search = searchName;
      if (this.activeItem !== 0) {
        this.getProductListOnEdit();
      } else {
        this.getProductListOnChange();
      }
    },
    hanndleChangePerpage(take) {
      this.filterGetProduct.take = take;
      if (this.activeItem !== 0) {
        this.getProductListOnEdit();
      } else {
        this.getProductListOnChange();
      }
    },
    pagination(page) {
      this.filterGetProduct.page = page;
      if (page === 1) {
        this.showing = this.filterGetProduct.page;
        this.showingTo = this.filterGetProduct.take;
      } else {
        this.showing =
          page * this.filterGetProduct.take - this.filterGetProduct.take;
        this.showingTo = this.showing + this.filterGetProduct.take;
      }
      if (this.activeItem !== 0) {
        this.getProductListOnEdit();
      } else {
        this.getProductListOnChange();
      }
    },
    async setShowonly(val) {
      this.filterGetProduct.page = 1;
      this.showing = this.filterGetProduct.page;
      this.showingTo = this.filterGetProduct.take;
      this.filterGetProduct.show_only_selected = val;
      if (this.activeItem !== 0) {
        this.getProductListOnEdit();
      } else {
        this.getProductListOnChange();
      }
    },
    async getProductListOnEdit() {
      this.isBusy = true;
      this.filterGetProduct.select_product = this.newEditSelectProduct;
      this.filterGetProduct.category_id = this.activeItem;
      await this.$store.dispatch(
        "ActionGetProductCategory",
        this.filterGetProduct
      );
      var data = await this.$store.state.moduleConnectAPI
        .stateGetProductCategory;
      if (data.result == 1) {
        for (let i = 0; i < data.detail.detail.length; i++) {
          if (data.detail.detail[i].is_check === true) {
            data.detail.detail[i].active = false;
            const even = (element) => element === data.detail.detail[i].id;
            var ischeck = this.IdList.some(even);
            if (ischeck === false) {
              this.IdList.push(data.detail.detail[i].id);
            }
          } else {
            data.detail.detail[i].active = true;
          }
          // const even = (element) => element.id === data.detail.detail[i].id;
          // var ischeck = this.filterGetProduct.select_product.some(even)
          // if (ischeck === false) {
          //   this.IdList.push(data.detail.detail[i].id)
          //   this.filterGetProduct.select_product.push({
          //     id: data.detail.detail[i].id,
          //     sort_order: data.detail.detail[i].sort_order
          //   })
          // } else {
          //   this.IdList[idIndex] = data.detail.detail[i].id
          //   var data2 = {
          //     id: data.detail.detail[i].id,
          //     sort_order: parseInt(data.detail.detail[i].sort_order)
          //   }
          //   this.filterGetProduct.select_product[idIndex] = data2
          // }
        }
        this.productList = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    async getProductListOnChange() {
      this.isBusy = true;
      var data = null;
      var setFilterGetProduct = null;
      if (this.activeItem !== 0) {
        setFilterGetProduct = {
          search: this.filterGetProduct.search,
          page: this.filterGetProduct.page,
          take: this.filterGetProduct.take,
          category_id: this.filterGetProduct.category_id,
          show_only_selected: this.filterGetProduct.show_only_selected,
          select_product: this.newEditSelectProduct,
          delete_product: this.filterGetProduct.delete_product,
        };
        this.filterGetProduct.category_id = this.activeItem;
        await this.$store.dispatch(
          "ActionGetProductCategory",
          this.filterGetProduct
        );
        data = await this.$store.state.moduleConnectAPI.stateGetProductCategory;
        if (data.result == 1) {
          for (let i = 0; i < data.detail.detail.length; i++) {
            if (data.detail.detail[i].is_check === true) {
              this.IdList.push(data.detail.detail[i].id);
              data.detail.detail[i].active = false;
              const even = (element) => element.id === data.detail.detail[i].id;
              var ischeck = this.filterGetProduct.select_product.some(even);
              const condition = (element) =>
                element.id === data.detail.detail[i].id;
              var idIndex =
                this.filterGetProduct.select_product.findIndex(condition);
              if (ischeck === false) {
                this.filterGetProduct.select_product.push({
                  id: data.detail.detail[i].id,
                  sort_order: data.detail.detail[i].sort_order,
                });
              } else {
                var data2 = {
                  id: data.detail.detail[i].id,
                  sort_order: parseInt(data.detail.detail[i].sort_order),
                };
                this.filterGetProduct.select_product[idIndex] = data2;
              }
            } else {
              data.detail.detail[i].active = true;
            }
          }
          this.productList = data.detail.detail;
          this.rows = data.detail.total_count;
          this.isBusy = false;
        }
      } else {
        setFilterGetProduct = {
          search: this.filterGetProduct.search,
          page: this.filterGetProduct.page,
          take: this.filterGetProduct.take,
          category_id: this.filterGetProduct.category_id,
          show_only_selected: this.filterGetProduct.show_only_selected,
          select_product: this.filterGetProduct.select_product,
          delete_product: this.filterGetProduct.delete_product,
        };
        await this.$store.dispatch(
          "ActionGetProductCategory",
          setFilterGetProduct
        );
        data = await this.$store.state.moduleConnectAPI.stateGetProductCategory;
        if (data.result == 1) {
          for (let i = 0; i < data.detail.detail.length; i++) {
            if (data.detail.detail[i].is_check === true) {
              this.IdList.push(data.detail.detail[i].id);
              data.detail.detail[i].active = false;
              const even = (element) => element.id === data.detail.detail[i].id;
              var ischeck2 = this.filterGetProduct.select_product.some(even);
              const condition = (element) =>
                element.id === data.detail.detail[i].id;
              var idIndex2 =
                this.filterGetProduct.select_product.findIndex(condition);
              if (ischeck2 === false) {
                this.filterGetProduct.select_product.push({
                  id: data.detail.detail[i].id,
                  sort_order: data.detail.detail[i].sort_order,
                });
              } else {
                var data3 = {
                  id: data.detail.detail[i].id,
                  sort_order: parseInt(data.detail.detail[i].sort_order),
                };
                this.filterGetProduct.select_product[idIndex2] = data3;
              }
            } else {
              data.detail.detail[i].active = true;
            }
          }
          this.productList = data.detail.detail;
          this.rows = data.detail.total_count;
          this.isBusy = false;
        }
      }
    },
    getProductList: async function () {
      this.isBusy = true;
      var data = null;
      this.productList = [];
      this.IdList = [];
      this.filterGetProduct.show_only_selected = false;
      this.filterGetProduct.search = "";
      this.filterGetProduct.select_product = [];
      this.filterGetProduct.delete_product = [];
      if (this.activeItem !== 0) {
        this.filterGetProduct.category_id = this.activeItem;
        await this.$store.dispatch(
          "ActionGetProductCategory",
          this.filterGetProduct
        );
        data = await this.$store.state.moduleConnectAPI.stateGetProductCategory;
        if (data.result == 1) {
          for (let i = 0; i < data.detail.detail.length; i++) {
            if (data.detail.detail[i].is_check === true) {
              this.IdList.push(data.detail.detail[i].id);
              data.detail.detail[i].active = false;
              const even = (element) => element.id === data.detail.detail[i].id;
              var ischeck = this.filterGetProduct.select_product.some(even);
              const condition = (element) =>
                element.id === data.detail.detail[i].id;
              var idIndex =
                this.filterGetProduct.select_product.findIndex(condition);
              if (ischeck === false) {
                this.filterGetProduct.select_product.push({
                  id: data.detail.detail[i].id,
                  sort_order: data.detail.detail[i].sort_order,
                });
              } else {
                var data2 = {
                  id: data.detail.detail[i].id,
                  sort_order: parseInt(data.detail.detail[i].sort_order),
                };
                this.filterGetProduct.select_product[idIndex] = data2;
              }
            } else {
              data.detail.detail[i].active = true;
            }
          }
          this.productList = data.detail.detail;
          this.rows = data.detail.total_count;
          this.isBusy = false;
        }
      } else {
        var setFilterGetProduct = {
          search: this.filterGetProduct.search,
          page: this.filterGetProduct.page,
          take: this.filterGetProduct.take,
          category_id: this.filterGetProduct.category_id,
          show_only_selected: this.filterGetProduct.show_only_selected,
          select_product: [],
          delete_product: [],
        };
        await this.$store.dispatch(
          "ActionGetProductCategory",
          setFilterGetProduct
        );
        data = await this.$store.state.moduleConnectAPI.stateGetProductCategory;
        if (data.result == 1) {
          for (let i = 0; i < data.detail.detail.length; i++) {
            data.detail.detail[i].active = true;
          }
          this.productList = data.detail.detail;
          this.rows = data.detail.total_count;
          this.isBusy = false;
        }
      }
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (parseInt(this.image.height) !== parseInt(this.image.width)) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            });
            this.deleteImage();
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.setDataImage(reader.result);
          }
        };
        img.src = await reader.result;
      };
    },
    async setDataImage(base64) {
      var url = await this.saveImagetoDb(base64, false);
      this.isLoadingImage = false;
      this.isDisable = false;
      this.showPreview = url;
      this.form.category.image_url = url;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    addParent(ref) {
      this.parentList.push(ref);
    },
    deleteImage(value) {
      this.form.category.image_url = null;
      this.showPreview = null;
    },
    checkForm: async function () {
      if (this.form.category.parent_id === 0) {
        this.checkrequiredtype.required = false;
      } else {
        this.checkrequiredtype.required = true;
      }
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.isLoadingData = true;
      if (this.form.category.status == true) {
        this.form.category.status = 1;
      } else {
        this.form.category.status = 0;
      }
      var data = null;
      if (this.activeItem == 0) {
        if (this.isSubCategory === true) {
          var setDatacreatesub = {
            name: this.form.category.name,
            parent_id: this.form.category.parent_id,
            image_url: this.form.category.image_url,
            sort_order: parseInt(this.form.category.sort_order),
            status: this.form.category.status,
            select_product: this.filterGetProduct.select_product,
          };
          await this.$store.dispatch(
            "ActionCreateSubCategory",
            setDatacreatesub
          );
          data = await this.$store.state.moduleConnectAPI
            .stateCreateSubCategory;
          if (data.result == 1) {
            this.isDisable = false;
            this.isLoadingData = false;
            this.successAlert().then(() => {
              this.getCategoryList();
              this.getParentCategoryList();
            });
          }
        } else {
          var setDatacreatemain = {
            name: this.form.category.name,
            image_url: this.form.category.image_url,
            sort_order: parseInt(this.form.category.sort_order),
            status: this.form.category.status,
          };
          await this.$store.dispatch(
            "ActionCreateMainCategory",
            setDatacreatemain
          );
          data = await this.$store.state.moduleConnectAPI
            .stateCreateMainCategory;
          if (data.result == 1) {
            this.isDisable = false;
            this.isLoadingData = false;
            this.successAlert().then(() => {
              this.getCategoryList();
              this.getParentCategoryList();
            });
          }
        }
      } else {
        if (this.isSubCategory === true) {
          var setDataupdatesub = {
            id: this.activeItem,
            name: this.form.category.name,
            parent_id: this.form.category.parent_id,
            image_url: this.form.category.image_url,
            sort_order: parseInt(this.form.category.sort_order),
            status: this.form.category.status,
            select_product: this.filterGetProduct.select_product,
            delete_product: this.filterGetProduct.delete_product,
          };
          await this.$store.dispatch(
            "ActionUpdateSubCategory",
            setDataupdatesub
          );
          data = await this.$store.state.moduleConnectAPI
            .stateUpdateSubCategory;
          if (data.result == 1) {
            this.isDisable = false;
            this.isLoadingData = false;
            this.successAlert().then(() => {
              this.getCategoryList();
              this.getParentCategoryList();
            });
          }
        } else {
          var setDataupdate = {
            id: this.activeItem,
            name: this.form.category.name,
            image_url: this.form.category.image_url,
            sort_order: parseInt(this.form.category.sort_order),
            status: this.form.category.status,
          };
          await this.$store.dispatch("ActionUpdateMainCategory", setDataupdate);
          data = await this.$store.state.moduleConnectAPI
            .stateUpdateMainCategory;
          if (data.result == 1) {
            this.isDisable = false;
            this.isLoadingData = false;
            this.successAlert().then(() => {
              this.getCategoryList();
              this.getParentCategoryList();
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 350px) and (max-width: 991px) {
  .footer-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.footer-action {
  box-shadow: 0px 5px 0px var(--secondary-color),
    0px 5px 10px var(--secondary-color);
  margin-left: var(--margin-spacing);
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
.preview-box-category {
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
</style>
